const Data =[
    {
        name: "Advait Gokhale",
        position: "Chief Executive Officer, LiGHT",
        imageUrl: "/assets/images/members/GB24/Advait Gokhale.png",
        facebookLink: "https://www.facebook.com/profile.php?id=100074639880196",
        linkedinLink: "https://www.linkedin.com/in/advait-gokhale-399a0b236/",
        email: "advait.gokhale@gyws.org"
    },
]

export default Data;