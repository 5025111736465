const Data = [
        {
            name: "Devaki Nandana Kalam",
            position: "Chief Executive Officer Prayas",
            imageUrl: "/assets/images/members/Devaki Nandana Kalam.jpg",
            facebookLink: "https://www.facebook.com/gyws.iitkgp",
            linkedinLink: "https://www.linkedin.com/company/gopali-youth-welfare-society/mycompany/",
            email: "gywsociety@gmail.com"
        },
    
]

export default Data;