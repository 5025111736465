const data = [
    {
        name: "Ratnesh Khandelwal",
        position: "CHIEF EXECUTIVE OFFICER [RISE]",
        imageUrl: "/assets/images/members/Ratnesh Khandelwal.jpg",
        facebookLink: "https://www.facebook.com/gyws.iitkgp",
        linkedinLink: "https://www.linkedin.com/company/gopali-youth-welfare-society/mycompany/"
    },
    {
        name: "Dipti Naik",
        position: "EXECUTIVE OFFICER, LiGHT SAMVEDNA",
        imageUrl: "/images/profile.png",
        facebookLink: "https://www.facebook.com/gyws.iitkgp",
        linkedinLink: "https://www.linkedin.com/company/gopali-youth-welfare-society/mycompany/"
    },
    
]

export default data;

