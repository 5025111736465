const Data = [
    {
        name: "Chinmay Jain",
        position: "TechOps Head",
        imageUrl: "/images/profile.png",
        facebookLink: "https://www.facebook.com/gyws.iitkgp",
        linkedinLink: "https://www.linkedin.com/company/gopali-youth-welfare-society/mycompany/",
        email: "gywsociety@gmail.com"
    },
    {
        name: "Debabrata Bhakat",
        position: "TechOps Head",
        imageUrl: "/assets/images/members/Heads21/Debabrata Bhakat.jpg",
        facebookLink: "https://www.facebook.com/gyws.iitkgp",
        linkedinLink: "https://www.linkedin.com/company/gopali-youth-welfare-society/mycompany/",
        email: "gywsociety@gmail.com"
    },
    {
        name: "Naman Goel",
        position: "TechOps Head",
        imageUrl: "/images/profile.png",
        facebookLink: "https://www.facebook.com/gyws.iitkgp",
        linkedinLink: "https://www.linkedin.com/company/gopali-youth-welfare-society/mycompany/",
        email: "gywsociety@gmail.com"
    },
    
]

export default Data;