const carouselData = [
    {
        legend: "IITians Empowering Youth For a Better Tomorrow",
        img: "/images/DSC_0110.JPG",
        alt: 'Aashayein',
    },
    {
        legend: "IITians Empowering Youth For a Better Tomorrow",
        img: "/images/DSC_0046.JPG",
        alt: 'Aashayein',
    },
    {
        legend: "IITians Empowering Youth For a Better Tomorrow",
        img: "/images/header_bg_1.jpg",
        alt: 'Students at Jagriti Vidya Mandir(JVM)',
    },
    {
        legend: "IITians Empowering Youth For a Better Tomorrow",
        img: "/images/JVM2.jpg",
        alt: 'We with the students',
    },
]

export default carouselData;